<template>
  <b-modal
    id="match-result-modal"
    :title="title"
    no-close-on-backdrop
    size="lg"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="d-flex flex-grow-1 justify-content-between">
        <b-col>
          <dismissible-message-box
            :auto-dismiss="3000"
            :show="showMessage"
            :variant="messageType"
            class="p-1"
            @onDismissibleMessageBoxDismissed="showMessage = false"
          >
            <feather-icon
              class="mr-50"
              icon="InfoIcon"
            />
            {{ message }}
          </dismissible-message-box>
        </b-col>
        <b-col
          sm="6"
          class="d-flex justify-content-end align-items-start"
        >
          <spinning-button
            :is-loading="loading"
            :disabled="loading"
            class="btn-primary mr-1"
            @click.native="saveMatchResults"
          >Submit score
          </spinning-button>
          <b-button
            variant="outline-primary"
            class="ml-1"
            @click="cancel"
          >
            Close
          </b-button>
          <!--          <b-button-->
          <!--            variant="outline-primary"-->
          <!--            class="mr-1"-->
          <!--            @click="handleReportPlayer"-->
          <!--          >Report player</b-button>-->

          <!--          <b-button-->
          <!--            variant="outline-primary"-->
          <!--            @click="handleAdminCall"-->
          <!--          >Call admin to help</b-button>-->
        </b-col>
      </b-row>
    </template>

    <b-row>
      <!-- opponent 1 -->
      <b-col
        class="d-flex flex-column align-items-center p-0"
        sm="6"
        md="5"
      >
        <!-- opponent info -->
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            :src="match?.opponent1?.avatar"
            size="80"
            variant="secondary"
          />

          <b-card-text class="mb-0 font-weight-bold mt-2">
            {{
              match?.opponent1?.participant?.name || match?.opponent1?.name || 'TBD'
            }}
          </b-card-text>

          <b-list-group class="mt-2 flex-wrap flex-row border-top">
            <b-list-group-item
              v-for="listItem in match?.opponent1?.participant?.lineup"
              :key="listItem.id"
              tag="div"
            >
              <div class="d-flex align-items-center">
                <b-avatar
                  :src="listItem.avatar"
                  variant="primary"
                />
                <b-card-text class="ml-1">
                  {{ listItem.name }}
                </b-card-text>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!-- opponent match result -->
        <div class="mt-1 d-flex justify-content-center">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'W'
                  ? 'success'
                  : 'outline-primary'
              "
              @click="setMatchResult1('W')"
            >
              W
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'D'
                  ? 'secondary'
                  : 'outline-primary'
              "
              @click="setMatchResult1('D')"
            >
              D
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'L' ? 'danger' : 'outline-primary'
              "
              @click="setMatchResult1('L')"
            >
              L
            </b-button>
          </b-button-group>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-checkbox
            v-model="forfeitOpponent1"
            class="custom-control-primary"
            value="true"
            @change="setForfeitStatus"
          >
            Forfeit
          </b-form-checkbox>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-input
            v-model="scoreOpponent1"
            class="w-50 text-center"
            style="border-color: #28c76f !important;"
            @input="handleScoreChange"
          />
        </div>
      </b-col>

      <b-col class="d-none d-md-flex align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <h3>VS</h3>
        </div>
      </b-col>

      <!-- opponent 2 -->
      <b-col
        class="d-flex flex-column align-items-center p-0 mt-2 mt-md-0"
        sm="6"
        md="5"
      >
        <!-- opponent2 info -->
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            :src="match?.opponent2?.avatar"
            size="80"
            variant="secondary"
          />

          <b-card-text class="mb-0 font-weight-bold mt-2">
            {{
              match?.opponent2?.participant?.name || match?.opponent2?.name || 'TBD'
            }}
          </b-card-text>

          <b-list-group class="mt-2 flex-wrap flex-row border-top">
            <b-list-group-item
              v-for="listItem in match?.opponent2?.participant?.lineup"
              :key="listItem.id"
              tag="div"
            >
              <div class="d-flex align-items-center">
                <b-avatar
                  :src="listItem.avatar"
                  variant="primary"
                />
                <b-card-text class="ml-1">
                  {{ listItem.name }}
                </b-card-text>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- opponent2 match result -->
        <div class="mt-1 d-flex justify-content-center">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'W'
                  ? 'success'
                  : 'outline-primary'
              "
              @click="setMatchResult2('W')"
            >
              W
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'D'
                  ? 'secondary'
                  : 'outline-primary'
              "
              @click="setMatchResult2('D')"
            >
              D
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'L' ? 'danger' : 'outline-primary'
              "
              @click="setMatchResult2('L')"
            >
              L
            </b-button>
          </b-button-group>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-checkbox
            v-model="forfeitOpponent2"
            class="custom-control-primary"
            value="true"
            @change="setForfeitStatus"
          >
            Forfeit
          </b-form-checkbox>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-input
            v-model="scoreOpponent2"
            class="w-50 text-center"
            type="number"
            style="border-color: #28c76f !important;"
            @input="handleScoreChange"
          />
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2 align-content-center">
      <b-col sm="3" />
      <b-col
        sm="5"
        class="d-flex justify-content-center"
      >
        <b-form-file
          v-model="screenshot"
          placeholder="Choose a screenshot ..."
          drop-placeholder="Drop screenshot here..."
        />
      </b-col>
      <b-col class="align-content-center">
        <b-button
          v-if="screenshotUrl"
          size="sm"

          variant="success"
          @click="openScreenshot"
        >
          Show
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="noResultEntered">
      <b-col>
        <p class="pl-2 pr-2 text-justify">
          Please enter the score and upload a screenshot if requested by the tournament organizer before submitting the
          score. Before submitting the score, please ensure that you have entered the correct score. If both teams enter
          the same score, the match will be closed. Otherwise, you will have the option to re-enter the correct score or
          call an admin.
        </p>
      </b-col>
    </b-row>

    <b-row
      v-else
      class="mt-2 flex-column"
    >
      <b-col
        v-if="myOpponentResults"
      >
        {{ myOpponent?.participant?.name }} entered: <b-badge
          small
          variant="light-info"
        >{{ `${myOpponent?.participant?.name} [ ${ myOpponent?.opponent1_score || '' } ][ ${ myOpponent?.opponent1_result || '' } ]` }}
        </b-badge> : <b-badge
          small
          variant="light-info"
        >{{ `${myOpponent?.participant?.name} [ ${ myOpponentResults?.opponent2_score || '' } ][ ${ myOpponentResults?.opponent2_result || '' } ]` }}</b-badge>
      </b-col>
      <b-col
        v-if="myOpponentResults === null"
      >
        {{ myOpponent?.participant?.name ? `${myOpponent?.participant?.name} did not enter the score yet!` : '' }}
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BButtonGroup,
  BCardText,
  BCol,
  BFormCheckbox, BFormFile,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BModal,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormFile,
    BAvatar,
    BCardText,
    BListGroupItem,
    BListGroup,
    BButtonGroup,
    BFormCheckbox,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    match: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      showSaveButton: true,
      forfeitOpponent1: false,
      forfeitOpponent2: false,
      scoreOpponent1: null,
      scoreOpponent2: null,
      opponent1MatchResult: null,
      opponent2MatchResult: null,
      showMessage: null,
      message: null,
      messageType: MessageType.SUCCESS,
      uploadedImage: null,
      loading: false,
      screenshot: null,
      screenshotUrl: null,
    }
  },
  computed: {
    myOpponentResults() {
      return this.match?.result?.participant_results?.find(result => result.participant_id !== this.myParticipant?.id) || null
    },
    myOpponent() {
      const x = this.match?.opponent1?.participant_id === this.myParticipant?.id
        ? this.match?.opponent2
        : this.match?.opponent1

      return x
    },
    me() {
      return this.$store.getters['user/getMe']
    },
    title() {
      return `${
          this.match?.opponent1?.participant?.name || 'TBD'
      } vs ${this.match?.opponent2?.participant?.name || 'TBD'} `
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    myParticipant() {
      const participantType = this.match?.opponent1?.participant_type

      if (participantType === 'team') {
        const myTeam = this.me?.teams?.find(team => this.match.opponent1.participant_id === team.id || this.match.opponent2.participant_id === team.id)

        if (this.match.opponent1.participant_id === myTeam.id) return this.match.opponent1

        if (this.match.opponent2.participant_id === myTeam.id) return this.match.opponent2
      } else {
        return this.me
      }
    },
    noResultEntered() {
      return !this.match?.result?.participant_results?.some(result => result.participant_id === this.myParticipant?.id)
    },
  },
  watch: {
    tabIndex(index) {
      this.showSaveButton = index === 0
    },
    match(val) {
      const myMatchResult = val?.result?.participant_results?.find(result => result.participant_id === this.myParticipant?.id)

      this.opponent1MatchResult = myMatchResult?.opponent1_result || null
      this.opponent2MatchResult = myMatchResult?.opponent2_result || null

      this.scoreOpponent1 = myMatchResult?.opponent1_score !== null ? myMatchResult?.opponent1_score : null
      this.scoreOpponent2 = myMatchResult?.opponent2_score !== null ? myMatchResult?.opponent2_score : null

      this.forfeitOpponent1 = myMatchResult?.opponent1_forfeit || false
      this.forfeitOpponent2 = myMatchResult?.opponent2_forfeit || false

      this.screenshotUrl = myMatchResult?.score_screenshot || null
    },
  },
  methods: {
    openScreenshot() {
      window.open(this.screenshotUrl, '_blank', 'noreferrer')
    },
    setMatchResult1(status) {
      switch (status) {
        case 'W':
          this.opponent1MatchResult = 'W'
          this.opponent2MatchResult = 'L'
          break
        case 'D':
          this.opponent1MatchResult = 'D'
          this.opponent2MatchResult = 'D'
          break
        case 'L':
          this.opponent1MatchResult = 'L'
          this.opponent2MatchResult = 'W'
          break
        default:
          this.opponent1MatchResult = null
          this.opponent2MatchResult = null
          break
      }

      this.forfeitOpponent1 = null
      this.forfeitOpponent2 = null
    },
    setMatchResult2(status) {
      switch (status) {
        case 'W':
          this.opponent2MatchResult = 'W'
          this.opponent1MatchResult = 'L'
          break
        case 'D':
          this.opponent2MatchResult = 'D'
          this.opponent1MatchResult = 'D'
          break
        case 'L':
          this.opponent2MatchResult = 'L'
          this.opponent1MatchResult = 'W'
          break
        default:
          this.opponent1MatchResult = null
          this.opponent2MatchResult = null
          break
      }

      this.forfeitOpponent1 = null
      this.forfeitOpponent2 = null
    },
    setForfeitStatus() {
      if (
        (this.forfeitOpponent1 && this.forfeitOpponent2)
          || (!this.forfeitOpponent1 && !this.forfeitOpponent2)
      ) {
        this.opponent1MatchResult = null
        this.opponent2MatchResult = null

        return
      }

      if (this.forfeitOpponent1) {
        this.opponent2MatchResult = 'W'
        this.opponent1MatchResult = 'L'

        return
      }

      if (this.forfeitOpponent2) {
        this.opponent1MatchResult = 'W'
        this.opponent2MatchResult = 'L'
      }
    },
    handleScoreChange() {
      if (this.scoreOpponent1 && this.scoreOpponent2) {
        if (this.scoreOpponent1 > this.scoreOpponent2) {
          this.opponent1MatchResult = 'W'
          this.opponent2MatchResult = 'L'
        } else if (this.scoreOpponent1 < this.scoreOpponent2) {
          this.opponent1MatchResult = 'L'
          this.opponent2MatchResult = 'W'
        } else {
          this.opponent1MatchResult = 'D'
          this.opponent2MatchResult = 'D'
        }
      }
    },
    async saveMatchResults() {
      this.message = []
      this.showMessage = false

      // ToDo: uncomment when deplyoing

      // if (this.match?.status_id === MatchStatusType.COMPLETE) {
      //   this.message = 'Match completed! You cannot change the result'
      //   this.showMessage = true
      //   this.messageType = MessageType.ERROR
      //
      //   return
      // }

      if (!this.opponent1MatchResult || !this.opponent2MatchResult) {
        this.message = 'Please enter match results'
        this.showMessage = true
        this.messageType = MessageType.ERROR

        return
      }

      if (this.scoreOpponent1 === null || this.scoreOpponent2 === null) {
        this.message = 'Please enter match score'
        this.showMessage = true
        this.messageType = MessageType.ERROR

        return
      }

      const payload = {
        match_result_id: this.match?.result?.id || null,
        participant_id: this.myParticipant?.id,
        opponent1_forfeit: this.forfeitOpponent1
          ? Boolean(this.forfeitOpponent1)
          : false,
        opponent2_forfeit: this.forfeitOpponent2
          ? Boolean(this.forfeitOpponent2)
          : false,
        opponent1_score: this.scoreOpponent1
          ? Number(this.scoreOpponent1)
          : null,
        opponent2_score: this.scoreOpponent2
          ? Number(this.scoreOpponent2)
          : null,
        opponent1_result: this.opponent1MatchResult,
        opponent2_result: this.opponent2MatchResult,
        screenshot: this.screenshot,
      }

      this.loading = true
      const { errors } = await this.$api.tournament.saveParticipantMatchResult(payload)
      this.loading = false

      if (errors) {
        this.message = 'There was a problem saving match result. Please try again or contact our support.'
        this.showMessage = true
        this.messageType = MessageType.ERROR

        return
      }

      this.message = 'Match result saved successfully'
      this.showMessage = true
      this.messageType = MessageType.SUCCESS

      setTimeout(() => {
        this.$bvModal.hide('match-result-modal')
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}

@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.dark-layout {
  .list-group {
    border-radius: 0;

    .list-group-item {
      border: none;
      background: none !important;

      flex: 1;
    }
  }
}
</style>
