var render = function () {
  var _vm$tournament;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Bracket ")])])], 1)], 1), ((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) < _vm.TournamentStatusType.IN_PROGRESS ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('feather-icon', {
    staticClass: "m-2",
    attrs: {
      "icon": "FrownIcon",
      "size": "50"
    }
  }), _vm._v(" Available when tournament is started ")], 1)], 1) : _c('div', {
    staticStyle: {
      "height": "calc(100% - 90px) !important"
    }
  }, [_vm.isLoading ? _c('b-row', {
    staticClass: "text-center p-2"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area scroll-area h-100",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('bracket', {
    key: 1,
    attrs: {
      "add-grand-finale-round": true,
      "flat-tree": _vm.winnerNodes,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId
    },
    on: {
      "onMatchInfoModalShow": _vm.handleMatchInfoModalShow
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref) {
        var player = _ref.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }])
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('bracket', {
    key: 2,
    attrs: {
      "flat-tree": _vm.looserNodes,
      "show-edit-results": _vm.canEditResults,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId
    },
    on: {
      "onMatchInfoModalShow": _vm.handleMatchInfoModalShow
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref2) {
        var player = _ref2.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('match-info-modal', {
    attrs: {
      "match": _vm.selectedMatch
    }
  }), _c('match-result-modal', {
    attrs: {
      "match": _vm.selectedMatch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }