var render = function () {
  var _vm$localMatch, _vm$localMatch$oppone, _vm$localMatch$oppone2, _vm$localMatch2, _vm$localMatch2$oppon, _vm$localMatch3, _vm$localMatch3$oppon, _vm$localMatch3$oppon2, _vm$localMatch4, _vm$localMatch4$oppon, _vm$localMatch5, _vm$localMatch5$oppon, _vm$localMatch6, _vm$localMatch6$oppon, _vm$localMatch6$oppon2, _vm$localMatch7, _vm$localMatch7$oppon, _vm$localMatch8, _vm$localMatch8$oppon, _vm$localMatch8$oppon2, _vm$localMatch9, _vm$localMatch9$oppon, _vm$localMatch10, _vm$localMatch10$oppo, _vm$localMatch11, _vm$localMatch11$resu, _vm$localMatch12, _vm$localMatch12$resu, _vm$localMatch13, _vm$localMatch13$resu, _vm$localMatch14, _vm$localMatch14$resu, _vm$localMatch15, _vm$localMatch15$resu, _vm$localMatch16, _vm$localMatch16$resu, _vm$localMatch17, _vm$localMatch17$resu, _vm$localMatch18, _vm$localMatch18$resu, _vm$localMatch19, _vm$localMatch19$resu, _vm$localMatch20, _vm$localMatch20$resu;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "match-info-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  }, [_c('b-container', {
    staticClass: "p-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$localMatch = _vm.localMatch) === null || _vm$localMatch === void 0 ? void 0 : (_vm$localMatch$oppone = _vm$localMatch.opponent1) === null || _vm$localMatch$oppone === void 0 ? void 0 : (_vm$localMatch$oppone2 = _vm$localMatch$oppone.participant) === null || _vm$localMatch$oppone2 === void 0 ? void 0 : _vm$localMatch$oppone2.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$localMatch2 = _vm.localMatch) !== null && _vm$localMatch2 !== void 0 && (_vm$localMatch2$oppon = _vm$localMatch2.opponent1) !== null && _vm$localMatch2$oppon !== void 0 && _vm$localMatch2$oppon.participant ? (_vm$localMatch3 = _vm.localMatch) === null || _vm$localMatch3 === void 0 ? void 0 : (_vm$localMatch3$oppon = _vm$localMatch3.opponent1) === null || _vm$localMatch3$oppon === void 0 ? void 0 : (_vm$localMatch3$oppon2 = _vm$localMatch3$oppon.participant) === null || _vm$localMatch3$oppon2 === void 0 ? void 0 : _vm$localMatch3$oppon2.name : ((_vm$localMatch4 = _vm.localMatch) === null || _vm$localMatch4 === void 0 ? void 0 : (_vm$localMatch4$oppon = _vm$localMatch4.opponent1) === null || _vm$localMatch4$oppon === void 0 ? void 0 : _vm$localMatch4$oppon.name) || 'TBD') + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$localMatch5 = _vm.localMatch) === null || _vm$localMatch5 === void 0 ? void 0 : (_vm$localMatch5$oppon = _vm$localMatch5.opponent1) === null || _vm$localMatch5$oppon === void 0 ? void 0 : _vm$localMatch5$oppon.lineup, function (participant, index) {
    var _participant$player;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$player = participant.player) === null || _participant$player === void 0 ? void 0 : _participant$player.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', [_vm._v("VS")])])]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$localMatch6 = _vm.localMatch) === null || _vm$localMatch6 === void 0 ? void 0 : (_vm$localMatch6$oppon = _vm$localMatch6.opponent2) === null || _vm$localMatch6$oppon === void 0 ? void 0 : (_vm$localMatch6$oppon2 = _vm$localMatch6$oppon.participant) === null || _vm$localMatch6$oppon2 === void 0 ? void 0 : _vm$localMatch6$oppon2.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$localMatch7 = _vm.localMatch) !== null && _vm$localMatch7 !== void 0 && (_vm$localMatch7$oppon = _vm$localMatch7.opponent2) !== null && _vm$localMatch7$oppon !== void 0 && _vm$localMatch7$oppon.participant ? (_vm$localMatch8 = _vm.localMatch) === null || _vm$localMatch8 === void 0 ? void 0 : (_vm$localMatch8$oppon = _vm$localMatch8.opponent2) === null || _vm$localMatch8$oppon === void 0 ? void 0 : (_vm$localMatch8$oppon2 = _vm$localMatch8$oppon.participant) === null || _vm$localMatch8$oppon2 === void 0 ? void 0 : _vm$localMatch8$oppon2.name : ((_vm$localMatch9 = _vm.localMatch) === null || _vm$localMatch9 === void 0 ? void 0 : (_vm$localMatch9$oppon = _vm$localMatch9.opponent2) === null || _vm$localMatch9$oppon === void 0 ? void 0 : _vm$localMatch9$oppon.name) || 'TBD') + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$localMatch10 = _vm.localMatch) === null || _vm$localMatch10 === void 0 ? void 0 : (_vm$localMatch10$oppo = _vm$localMatch10.opponent2) === null || _vm$localMatch10$oppo === void 0 ? void 0 : _vm$localMatch10$oppo.lineup, function (participant, index) {
    var _participant$player2;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$player2 = participant.player) === null || _participant$player2 === void 0 ? void 0 : _participant$player2.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('h4', [_vm._v(_vm._s(_vm.matchStatusInfo))])])], 1), (_vm$localMatch11 = _vm.localMatch) !== null && _vm$localMatch11 !== void 0 && (_vm$localMatch11$resu = _vm$localMatch11.result) !== null && _vm$localMatch11$resu !== void 0 && _vm$localMatch11$resu.opponent1_result && (_vm$localMatch12 = _vm.localMatch) !== null && _vm$localMatch12 !== void 0 && (_vm$localMatch12$resu = _vm$localMatch12.result) !== null && _vm$localMatch12$resu !== void 0 && _vm$localMatch12$resu.opponent2_result ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [((_vm$localMatch13 = _vm.localMatch) === null || _vm$localMatch13 === void 0 ? void 0 : (_vm$localMatch13$resu = _vm$localMatch13.result) === null || _vm$localMatch13$resu === void 0 ? void 0 : _vm$localMatch13$resu.opponent1_score) !== null ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$localMatch14 = _vm.localMatch) === null || _vm$localMatch14 === void 0 ? void 0 : (_vm$localMatch14$resu = _vm$localMatch14.result) === null || _vm$localMatch14$resu === void 0 ? void 0 : _vm$localMatch14$resu.opponent1_score))])]) : _vm._e(), _c('div', {
    staticClass: "mt-auto"
  }, [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$localMatch15 = _vm.localMatch) === null || _vm$localMatch15 === void 0 ? void 0 : (_vm$localMatch15$resu = _vm$localMatch15.result) === null || _vm$localMatch15$resu === void 0 ? void 0 : _vm$localMatch15$resu.opponent1_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$localMatch16 = _vm.localMatch) === null || _vm$localMatch16 === void 0 ? void 0 : (_vm$localMatch16$resu = _vm$localMatch16.result) === null || _vm$localMatch16$resu === void 0 ? void 0 : _vm$localMatch16$resu.opponent1_result)) + " ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [((_vm$localMatch17 = _vm.localMatch) === null || _vm$localMatch17 === void 0 ? void 0 : (_vm$localMatch17$resu = _vm$localMatch17.result) === null || _vm$localMatch17$resu === void 0 ? void 0 : _vm$localMatch17$resu.opponent2_score) !== null ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$localMatch18 = _vm.localMatch) === null || _vm$localMatch18 === void 0 ? void 0 : (_vm$localMatch18$resu = _vm$localMatch18.result) === null || _vm$localMatch18$resu === void 0 ? void 0 : _vm$localMatch18$resu.opponent2_score))])]) : _vm._e(), _c('div', {
    staticClass: "mt-auto"
  }, [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$localMatch19 = _vm.localMatch) === null || _vm$localMatch19 === void 0 ? void 0 : (_vm$localMatch19$resu = _vm$localMatch19.result) === null || _vm$localMatch19$resu === void 0 ? void 0 : _vm$localMatch19$resu.opponent2_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$localMatch20 = _vm.localMatch) === null || _vm$localMatch20 === void 0 ? void 0 : (_vm$localMatch20$resu = _vm$localMatch20.result) === null || _vm$localMatch20$resu === void 0 ? void 0 : _vm$localMatch20$resu.opponent2_result)) + " ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }