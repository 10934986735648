var render = function () {
  var _vm$match, _vm$match$opponent, _vm$match2, _vm$match2$opponent, _vm$match2$opponent$p, _vm$match3, _vm$match3$opponent, _vm$match4, _vm$match4$opponent, _vm$match4$opponent$p, _vm$match5, _vm$match5$opponent, _vm$match6, _vm$match6$opponent, _vm$match6$opponent$p, _vm$match7, _vm$match7$opponent, _vm$match8, _vm$match8$opponent, _vm$match8$opponent$p, _vm$myOpponent, _vm$myOpponent$partic, _vm$myOpponent2, _vm$myOpponent2$parti, _vm$myOpponent3, _vm$myOpponent4, _vm$myOpponent5, _vm$myOpponent5$parti, _vm$myOpponentResults, _vm$myOpponentResults2, _vm$myOpponent6, _vm$myOpponent6$parti, _vm$myOpponent7, _vm$myOpponent7$parti;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "match-result-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "d-flex flex-grow-1 justify-content-between"
        }, [_c('b-col', [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "auto-dismiss": 3000,
            "show": _vm.showMessage,
            "variant": _vm.messageType
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showMessage = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('b-col', {
          staticClass: "d-flex justify-content-end align-items-start",
          attrs: {
            "sm": "6"
          }
        }, [_c('spinning-button', {
          staticClass: "btn-primary mr-1",
          attrs: {
            "is-loading": _vm.loading,
            "disabled": _vm.loading
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.saveMatchResults($event);
            }
          }
        }, [_vm._v("Submit score ")]), _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      }
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center p-0",
    attrs: {
      "sm": "6",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match = _vm.match) === null || _vm$match === void 0 ? void 0 : (_vm$match$opponent = _vm$match.opponent1) === null || _vm$match$opponent === void 0 ? void 0 : _vm$match$opponent.avatar,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('b-card-text', {
    staticClass: "mb-0 font-weight-bold mt-2"
  }, [_vm._v(" " + _vm._s(((_vm$match2 = _vm.match) === null || _vm$match2 === void 0 ? void 0 : (_vm$match2$opponent = _vm$match2.opponent1) === null || _vm$match2$opponent === void 0 ? void 0 : (_vm$match2$opponent$p = _vm$match2$opponent.participant) === null || _vm$match2$opponent$p === void 0 ? void 0 : _vm$match2$opponent$p.name) || ((_vm$match3 = _vm.match) === null || _vm$match3 === void 0 ? void 0 : (_vm$match3$opponent = _vm$match3.opponent1) === null || _vm$match3$opponent === void 0 ? void 0 : _vm$match3$opponent.name) || 'TBD') + " ")]), _c('b-list-group', {
    staticClass: "mt-2 flex-wrap flex-row border-top"
  }, _vm._l((_vm$match4 = _vm.match) === null || _vm$match4 === void 0 ? void 0 : (_vm$match4$opponent = _vm$match4.opponent1) === null || _vm$match4$opponent === void 0 ? void 0 : (_vm$match4$opponent$p = _vm$match4$opponent.participant) === null || _vm$match4$opponent$p === void 0 ? void 0 : _vm$match4$opponent$p.lineup, function (listItem) {
    return _c('b-list-group-item', {
      key: listItem.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": listItem.avatar,
        "variant": "primary"
      }
    }), _c('b-card-text', {
      staticClass: "ml-1"
    }, [_vm._v(" " + _vm._s(listItem.name) + " ")])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-button-group', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'W' ? 'success' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('W');
      }
    }
  }, [_vm._v(" W ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'D' ? 'secondary' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('D');
      }
    }
  }, [_vm._v(" D ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'L' ? 'danger' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('L');
      }
    }
  }, [_vm._v(" L ")])], 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "value": "true"
    },
    on: {
      "change": _vm.setForfeitStatus
    },
    model: {
      value: _vm.forfeitOpponent1,
      callback: function callback($$v) {
        _vm.forfeitOpponent1 = $$v;
      },
      expression: "forfeitOpponent1"
    }
  }, [_vm._v(" Forfeit ")])], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-input', {
    staticClass: "w-50 text-center",
    staticStyle: {
      "border-color": "#28c76f !important"
    },
    on: {
      "input": _vm.handleScoreChange
    },
    model: {
      value: _vm.scoreOpponent1,
      callback: function callback($$v) {
        _vm.scoreOpponent1 = $$v;
      },
      expression: "scoreOpponent1"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-none d-md-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', [_vm._v("VS")])])]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center p-0 mt-2 mt-md-0",
    attrs: {
      "sm": "6",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match5 = _vm.match) === null || _vm$match5 === void 0 ? void 0 : (_vm$match5$opponent = _vm$match5.opponent2) === null || _vm$match5$opponent === void 0 ? void 0 : _vm$match5$opponent.avatar,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('b-card-text', {
    staticClass: "mb-0 font-weight-bold mt-2"
  }, [_vm._v(" " + _vm._s(((_vm$match6 = _vm.match) === null || _vm$match6 === void 0 ? void 0 : (_vm$match6$opponent = _vm$match6.opponent2) === null || _vm$match6$opponent === void 0 ? void 0 : (_vm$match6$opponent$p = _vm$match6$opponent.participant) === null || _vm$match6$opponent$p === void 0 ? void 0 : _vm$match6$opponent$p.name) || ((_vm$match7 = _vm.match) === null || _vm$match7 === void 0 ? void 0 : (_vm$match7$opponent = _vm$match7.opponent2) === null || _vm$match7$opponent === void 0 ? void 0 : _vm$match7$opponent.name) || 'TBD') + " ")]), _c('b-list-group', {
    staticClass: "mt-2 flex-wrap flex-row border-top"
  }, _vm._l((_vm$match8 = _vm.match) === null || _vm$match8 === void 0 ? void 0 : (_vm$match8$opponent = _vm$match8.opponent2) === null || _vm$match8$opponent === void 0 ? void 0 : (_vm$match8$opponent$p = _vm$match8$opponent.participant) === null || _vm$match8$opponent$p === void 0 ? void 0 : _vm$match8$opponent$p.lineup, function (listItem) {
    return _c('b-list-group-item', {
      key: listItem.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": listItem.avatar,
        "variant": "primary"
      }
    }), _c('b-card-text', {
      staticClass: "ml-1"
    }, [_vm._v(" " + _vm._s(listItem.name) + " ")])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-button-group', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'W' ? 'success' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult2('W');
      }
    }
  }, [_vm._v(" W ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'D' ? 'secondary' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult2('D');
      }
    }
  }, [_vm._v(" D ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'L' ? 'danger' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult2('L');
      }
    }
  }, [_vm._v(" L ")])], 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "value": "true"
    },
    on: {
      "change": _vm.setForfeitStatus
    },
    model: {
      value: _vm.forfeitOpponent2,
      callback: function callback($$v) {
        _vm.forfeitOpponent2 = $$v;
      },
      expression: "forfeitOpponent2"
    }
  }, [_vm._v(" Forfeit ")])], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-input', {
    staticClass: "w-50 text-center",
    staticStyle: {
      "border-color": "#28c76f !important"
    },
    attrs: {
      "type": "number"
    },
    on: {
      "input": _vm.handleScoreChange
    },
    model: {
      value: _vm.scoreOpponent2,
      callback: function callback($$v) {
        _vm.scoreOpponent2 = $$v;
      },
      expression: "scoreOpponent2"
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "mt-2 align-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "3"
    }
  }), _c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a screenshot ...",
      "drop-placeholder": "Drop screenshot here..."
    },
    model: {
      value: _vm.screenshot,
      callback: function callback($$v) {
        _vm.screenshot = $$v;
      },
      expression: "screenshot"
    }
  })], 1), _c('b-col', {
    staticClass: "align-content-center"
  }, [_vm.screenshotUrl ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "success"
    },
    on: {
      "click": _vm.openScreenshot
    }
  }, [_vm._v(" Show ")]) : _vm._e()], 1)], 1), _vm.noResultEntered ? _c('b-row', [_c('b-col', [_c('p', {
    staticClass: "pl-2 pr-2 text-justify"
  }, [_vm._v(" Please enter the score and upload a screenshot if requested by the tournament organizer before submitting the score. Before submitting the score, please ensure that you have entered the correct score. If both teams enter the same score, the match will be closed. Otherwise, you will have the option to re-enter the correct score or call an admin. ")])])], 1) : _c('b-row', {
    staticClass: "mt-2 flex-column"
  }, [_vm.myOpponentResults ? _c('b-col', [_vm._v(" " + _vm._s((_vm$myOpponent = _vm.myOpponent) === null || _vm$myOpponent === void 0 ? void 0 : (_vm$myOpponent$partic = _vm$myOpponent.participant) === null || _vm$myOpponent$partic === void 0 ? void 0 : _vm$myOpponent$partic.name) + " entered: "), _c('b-badge', {
    attrs: {
      "small": "",
      "variant": "light-info"
    }
  }, [_vm._v(_vm._s("".concat((_vm$myOpponent2 = _vm.myOpponent) === null || _vm$myOpponent2 === void 0 ? void 0 : (_vm$myOpponent2$parti = _vm$myOpponent2.participant) === null || _vm$myOpponent2$parti === void 0 ? void 0 : _vm$myOpponent2$parti.name, " [ ").concat(((_vm$myOpponent3 = _vm.myOpponent) === null || _vm$myOpponent3 === void 0 ? void 0 : _vm$myOpponent3.opponent1_score) || '', " ][ ").concat(((_vm$myOpponent4 = _vm.myOpponent) === null || _vm$myOpponent4 === void 0 ? void 0 : _vm$myOpponent4.opponent1_result) || '', " ]")) + " ")]), _vm._v(" : "), _c('b-badge', {
    attrs: {
      "small": "",
      "variant": "light-info"
    }
  }, [_vm._v(_vm._s("".concat((_vm$myOpponent5 = _vm.myOpponent) === null || _vm$myOpponent5 === void 0 ? void 0 : (_vm$myOpponent5$parti = _vm$myOpponent5.participant) === null || _vm$myOpponent5$parti === void 0 ? void 0 : _vm$myOpponent5$parti.name, " [ ").concat(((_vm$myOpponentResults = _vm.myOpponentResults) === null || _vm$myOpponentResults === void 0 ? void 0 : _vm$myOpponentResults.opponent2_score) || '', " ][ ").concat(((_vm$myOpponentResults2 = _vm.myOpponentResults) === null || _vm$myOpponentResults2 === void 0 ? void 0 : _vm$myOpponentResults2.opponent2_result) || '', " ]")))])], 1) : _vm._e(), _vm.myOpponentResults === null ? _c('b-col', [_vm._v(" " + _vm._s((_vm$myOpponent6 = _vm.myOpponent) !== null && _vm$myOpponent6 !== void 0 && (_vm$myOpponent6$parti = _vm$myOpponent6.participant) !== null && _vm$myOpponent6$parti !== void 0 && _vm$myOpponent6$parti.name ? "".concat((_vm$myOpponent7 = _vm.myOpponent) === null || _vm$myOpponent7 === void 0 ? void 0 : (_vm$myOpponent7$parti = _vm$myOpponent7.participant) === null || _vm$myOpponent7$parti === void 0 ? void 0 : _vm$myOpponent7$parti.name, " did not enter the score yet!") : '') + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }